@charset "UTF-8";
@use "../global" as *;

html {
  font-size: 62.5%;
  font-family: $NotoSansJP;
  @include bpTb {
    font-size: 2.66vw;
  }
}
body {
  color: $textColor;
  @include fz($baseFont);
  font-weight: $baseWeight;
  line-height: $lineHeight;
  letter-spacing: $baseletter;
  word-break: break-word;
  @include bpTb {
    @include fz($baseFontSp);
  }
}
a {
  transition: opacity .3s ease;
  img {
    transition-property: opacity;
    transition-duration: .2s;
    backface-visibility: hidden;
  }
}
@media (hover: hover) and (pointer: fine) {
  a {
    &:hover {
      opacity: .8;
      img {
        opacity: .8;
      }
    }
  }
}
img {
  max-width: 100%;
  height: auto;
}
button,
input,
optgroup,
select,
textarea {
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
}
small {
  font-size: inherit;
}
