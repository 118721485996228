@charset "UTF-8";
@use "../global" as *;

.m-btn1 {
    width: min(100%, 359px);
    @include bpTb {
        width: min(100%, 30rem);
    }
    .m-btn1__link {
        display: block;
        width: 100%;
        font-size: $text-22;
        @include font(22, 500, 30, 300);
        font-family: $mincho;
        text-align: center;
        padding: 0.86em 0.5em;
        color: $whiteColor;
        background-color: $textColor;
        position: relative;
        @include bpTb {
            font-size: $text-22-sp;
        }
        &::before {
            content: '';
            display: inline-block;
            background-image: url('../img/arrow-btn01.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 65px;
            padding-top: 3.5px;
            top: 50%;
            right: 23px;
            transform: translateY(-50%);
            @include bpTb {
                width: 4.5rem;
                padding-top: 0.35rem;
                right: 1.6rem;
            }
        }
        &.m-btn1__link--smallarrow {
            &::before {
                width: 49px;
                padding-top: 3.5px;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                @include bpTb {
                    width: 3.5rem;
                    padding-top: 0.2rem;
                    right: 1.2rem;
                }
            }
        }
    }
}

.m-btn1Small {
    width: min(100%, 142px);
    @include bpTb {
        width: min(100%, 14rem);
    }
    .m-btn1Small__link {
        display: block;
        width: 100%;
        font-size: $text-12;
        @include font(12, 500, 18, 50);
        font-family: $mincho;
        padding: 0.5em 1.7em;
        color: $whiteColor;
        background-color: $textColor;
        position: relative;
        @include bpTb {
            font-size: $text-12-sp;
        }
        &::before {
            content: '';
            display: inline-block;
            background-image: url('../img/arrow-btn01.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 29px;
            padding-top: 2.25px;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            @include bpTb {
                width: 2.5rem;
                padding-top: 0.2rem;
                right: 0.8rem;
            }
        }
    }
}
.m-btn1Small2 {
    width: min(100%, 110px);
    @include bpTb {
        width: min(100%, 11rem);
    }
    .m-btn1Small2__link {
        display: block;
        width: 100%;
        font-size: $text-10;
        @include font(10, 500, 14, 50);
        font-family: $mincho;
        text-align: center;
        padding: 0.5em 1.7em;
        color: $whiteColor;
        background-color: $textColor;
        position: relative;
        @include bpTb {
            font-size: $text-10-sp;
        }
    }
}

.m-btn2 {
    width: min(100%, 174px);
    @include bpTb {
        width: min(100%, 18rem);
    }
    .m-btn2__link {
        display: block;
        width: 100%;
        font-size: $text-14;
        @include font(14, 500, 33, 50);
        text-align: center;
        padding: 0.3em 0.5em;
        color: $whiteColor;
        background: linear-gradient(to bottom, rgba(0,129,240,1), rgba(110,210,255,1));
        border-radius: 3rem;
        @include bpTb {
            font-size: $text-14-sp;
        }
    }
}