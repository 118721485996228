@charset "UTF-8";
@use "../global" as *;

.m-title-big {
    display: inline-block;
    font-size: 150px;
    @include font(150, 400, 130, 0);
    font-family: DIN1451;
    color: rgba(0,110,188,0.2);
    position: absolute;
    @include bpPcs {
        font-size: 100px;
    }
    @include bpTb {
        font-size: 8rem;
    }
    &.m-title-big--2 {
        font-size: 196px;
        @include font(196, 400, 180, 50);
        color: rgba(255,255,255,0.25);
        @include bpPcs {
            font-size: 120px;
        }
        @include bpTb {
            font-size: 10rem;
        }
    }
    &.m-title-big--green {
        color: rgba(198,248,213,0.3);
    }
    &.m-title-big--pink {
        color: rgba(248,198,198,0.17);
    }
    &.m-title-big--blue {
        color: rgba(30,144,255,0.05);
    }
}

.m-title1 {
    font-size: $text-30;
    @include font(30, 500, 50, 300);
    text-align: center;
    padding-bottom: 0.5em;
    border-bottom: 0.1rem solid $color-gray2;
    position: relative;
    @include bpTb {
        font-size: $text-30-sp;
    }
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        height: 0.5rem;
        bottom: -0.3rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
    }
    &.m-title1--blue {
        &::before {
        width: min(100%, 332px);
        background-color: $color-blue4;
            @include bpTb {
                width: min(45%, 16rem);
            }
        }   
    }
    &.m-title1--black {
        &::before {
        width: min(100%, 332px);
        background-color: $textColor;
            @include bpTb {
                width: min(45%, 16rem);
            }
        }   
    }
}

.m-title2 {
    font-size: $text-36;
    @include font(36, 500, 48, 300);
    padding-left: 0.5em;
    border-left: 0.8rem solid $color-blue4;
    @include bpTb {
        font-size: $text-36-sp;
    }
}