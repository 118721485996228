/*-- componentフォルダの中の_index.scss --*/
@use "btn";
@use "form";
@use "page-flex";
@use "main-visual";
@use "pagenavi";
@use "tel";
@use "title";
@use "wordpress";
@use "wrapper";
//新しくパーシャルを作れば、ここに追記していく