@charset "UTF-8";
@use "../global" as *;

.pageFlex-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.pageFlex-container__footer {
    margin-top: auto;
    z-index: 5;
    position: relative;
}