@charset "UTF-8";
@use "../global" as *;

.wp-post {
    &::before {
        content: "";
        display: table;
    }

    &::after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
    }

    &> :last-child {
        margin-bottom: 0;
    }

    p {
        font-size: $text-18;
        @include font(18, 500, 45, 0);
        font-family: $mincho;
        @include bpTb {
            font-size: $text-18-sp;
        }
    }

    span,
    em,
    strong {
        font-size: inherit;
        color: inherit;
    }

    strong,
    b {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    blockquote {
        display: block;
        margin-top: 3em;
        -webkit-padding-before: 1em;
        -webkit-padding-after: 1em;
        -webkit-padding-start: 40px;
        -webkit-padding-end: 40px;
        background-color: #ccc;
        p {
            margin-top: 0;
        }
        cite {
            display: block;
            text-align: right;
        }
    }

    h1 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    h2 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    h3 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    h4 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    h5 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    h6 {
        font-size: $text-24;
        @include font(24, 500, 50, 100);
        font-family: $mincho;
        margin-top: 1.4em;
        @include bpTb {
            font-size: $text-24-sp;
        }
    }

    table {
        width: 100%;
        margin-top: 30px;
        font-size: 16px;
        @include font(16, 400, 32, 0);
        @include bpTb {
            font-size: 1.4rem;
            @include font(14, 400, 28, 0);
        }

        th {
        width: 25%;
        background-color: #eee;
        padding: 4px;
        border: 1px solid #aaa;
        }

        td {
        padding: 4px;
        border: 1px solid #aaa;
        }
    }

    ul {
        list-style: circle;
        padding-left: 1.2em;
        margin-top: 1em;
        font-size: 16px;
        @include font(16, 400, 32, 0);
        @include bpTb {
            font-size: 1.4rem;
            @include font(14, 400, 28, 0);
        }
    }

    ol {
        list-style: decimal;
        padding-left: 1.2em;
        margin-top: 1em;
        font-size: 16px;
        @include font(16, 400, 32, 0);
        @include bpTb {
            font-size: 1.4rem;
            @include font(14, 400, 28, 0);
        }
    }

    a {
        text-decoration: underline;
        color: #003cff;

        &:hover {
        color: #ff0004;
        }
    }

    .wp-block-image {
        margin-top: 70px;
        margin-bottom: 65px;
        @include bpTb {
            margin-top: 5rem;
            margin-bottom: 4.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    img[class*="wp-image-"],
    img[class*="attachment-"],
    img.pict {
        margin: 1em 5px 2em 0;
        display: block;
    }

    img.pict {
        margin: 1em auto 2em;
    }

    img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    img.alignright {
        padding: 4px;
        margin: 0 0 0 7px;
        display: inline;
        vertical-align: top;
    }

    img.alignleft {
        padding: 4px;
        margin: 0 7px 0 0;
        display: inline;
        vertical-align: top;
    }

    .alignright {
        float: right;
    }

    .alignleft {
        float: left;
    }

    iframe {
        max-width: 100% !important;
    }
    .iframe {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        margin-top: 45px;
        @include bpTb {
            margin-top: 4rem;
        }
    }
    .iframe iframe{
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
    }

}