@charset "UTF-8";
@use "../global" as *;

#contact {
    .m-contact {
        margin-top: 189px;
        @include bpTb {
            margin-top: 14rem;
        }
        .m-contact__container {
            max-width: 687px;
            margin-left: 95px;
            @include bpPcs {
                margin-inline: auto;
            }
            @include bpTb {
                max-width: none;
            }
        }
        .contact-table {
            display: block;
            width: 100%;
            table-layout: fixed;
            tbody {
                display: block;
                width: 100%;
            }
            .contact-table__box {
                display: flex;
                margin-top: 23px;
                @include bpTb {
                    display: block;
                    margin-top: 1.7rem;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            .contact-table__head {
                width: min(100%, 167px);
                display: block;
                font-size: $text-18;
                @include font(18, 500, 46, 0);
                font-family: $mincho;
                position: relative;
                @include bpTb {
                    width: 100%;
                    font-size: $text-18-sp;
                }
                .req {
                    &::after {
                        content: '*';
                        display: inline-block;
                    }
                }
            }
            .contact-table__detail {
                flex: 1;
                display: block;
                font-size: 20px;
                @include font(18, 500, 46, 0);
                font-family: $mincho;
                @include bpTb {
                    font-size: $text-18-sp;
                }
                .contact-table__area {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding: 5px 15px;
                    background-color: $whiteColor;
                    @include bpTb {
                        height: 4.5rem;
                        padding: 0.5rem 1.5rem;
                    }
                }
                .contact-table__content {
                    height: 377px;
                    padding: 15px;
                    resize: none;
                    @include bpTb {
                        height: 27rem;
                        padding: 1.5rem;
                    }
                }
            }
            .error {
                font-size: $text-14;
                @include font(14, 500, 20, 50);
                color: $color-pink1 !important;
                @include bpTb {
                    font-size: $text-14-sp;
                }
            }
        }
        .contact-check {
            display: flex;
            align-items: center;
            max-width: 520px;
            margin-top: 20px;
            margin-left: auto;
            @include bpTb {
                display: block;
                max-width: none;
            }
            .contact-check__lable {
                display: flex;
                @include bpTb {
                    justify-content: center;
                }
                span {
                    display: block;
                    font-size: $text-14;
                    @include font(14, 400, 20, 50);
                    padding-left: 2em;
                    cursor:pointer;
                    position: relative;
                    @include bpTb {
                        font-size: $text-14-sp;
                    }
                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 0.1rem solid $color-black1;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        @include bpTb {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 7px;
                        border-left: 0.3rem solid $color-black1;
                        border-bottom: 0.3rem solid $color-black1;
                        position: absolute;
                        top: 5px;
                        left: 3.5px;
                        transform: rotate(-45deg);
                        opacity: 0;
                        @include bpTb {
                            width: 1.2rem;
                            height: 0.7rem;
                            top: 0.5rem;
                            left: 0.4rem;
                        }
                    }
                }
                input[type=checkbox]:checked + span::after {
                    opacity: 1;
                }
                input[type=checkbox] {
                    display:none;
                }
            }
            .contact-check__link {
                display: block;
                font-size: $text-13;
                @include font(13, 500, 20, 50);
                text-align: center;
                padding: 0.1em 1.3em;
                margin-left: 2.3em;
                color: $color-blue4;
                border: 0.2rem solid $color-blue4;
                border-radius: 1.4rem;
                @include bpTb {
                    font-size: $text-13-sp;
                    width: min(100%, 20rem);
                    margin-inline: auto;
                    margin-top: 2rem;
                }
            }
        }
        // 入力画面用
        .mw_wp_form.mw_wp_form_input {
            .button-wrap {
                margin-top: 107px;
                margin-left: 90px;
                @include bpTb {
                    margin-top: 5rem;
                    margin-inline: auto;
                }
                .button01 {
                    display: block;
                    width: min(100%, 359px);
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    margin-inline: auto;
                    color: $whiteColor;
                    background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                    opacity: 1;
                    transition: opacity .5s ease;
                    @include bpTb {
                        width: 85%;
                        font-size: $text-22-sp;
                    }
                }
                .button01[disabled] {
                    opacity: .5;
                }
            }
        }
        // 確認画面
        .mw_wp_form.mw_wp_form_confirm {
            .contact-table__detail {
                font-weight: 400;
            }
            .contact-check {
                display: none;
            }
            .button-wrap {
                display: flex;
                justify-content: space-between;
                margin-top: 82px;
                @include bpTb {
                    margin-top: 7.1rem;
                }
                .button01 {
                    display: block;
                    width: 46%;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    margin-inline: auto;
                    color: $whiteColor;
                    background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
                .button02 {
                    display: block;
                    width: 46%;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    margin-inline: auto;
                    color: $whiteColor;
                    background-color: $color-gray1;
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
            }    
        }
        // 完了画面
        .contact-complete {
            .contact-complete__text {
                font-size: $text-18;
                @include font(18, 500, 36, 50);
                font-family: $mincho;
                text-align: center;
                @include bpTb {
                    font-size: $text-18-sp;
                }
            }
            .contact-complete-btn {
                display: block;
                width: min(100%, 359px);
                margin-inline: auto;
                margin-top: 7rem;
                @include bpTb {
                    width: min(100%, 25rem);
                }
                .contact-complete-btn__link {
                    display: block;
                    width: 100%;
                    font-size: $text-22;
                    @include font(22, 500, 28, 300);
                    font-family: $mincho;
                    text-align: center;
                    padding: 0.95em 1em;
                    color: $whiteColor;
                    background: linear-gradient(to bottom, rgb(0, 129, 240), rgb(110, 210, 255));
                    opacity: 1;
                    transition: opacity .5s ease;
                    @include bpTb {
                        font-size: $text-22-sp;
                    }
                }
            }
        }
    }
}