@charset "UTF-8";
@use "../global" as *;

// 下層ページメインビジュアル
.m-fv {
    height: 417px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    @include bpPcs {
        height: 19rem;
    }
    .m-fv-title {
        width: min(100%, 442px);
        height: 169px;
        position: absolute;
        bottom: -84px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-black3;
        z-index: 5;
        @include bpTb {
            height: 10rem;
            width: min(100%, 28rem);
            bottom: -5rem;
        }
        .m-fv-title__box {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .m-fv-title__small {
                display: block;
                font-size: $text-20;
                @include font(20, 500, 30, 50);
                font-family: $mincho;
                margin-bottom: 0.9em;
                text-align: center;
                color: $whiteColor;
                @include bpTb {
                    font-size: $text-20-sp;
                    margin-bottom: 0.4em;
                }
            }
            .m-fv-title__big {
                font-size: $text-29;
                @include font(29, 500, 50, 50);
                font-family: $mincho;
                text-align: center;
                color: $whiteColor;
                @include bpTb {
                    font-size: $text-29-sp;
                }
            }
        }
    }
}