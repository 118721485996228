@charset "UTF-8";
@use "../global" as *;

.m-pagenavi {
    .wp-pagenavi {
        display: flex;
        justify-content: center;
        .page,.current,.previouspostslink,.nextpostslink,.last,.first,.extend  {
            font-size: $text-12;
            @include font(12, 400, 50, 50);
            @include bpTb {
                font-size: $text-21-sp;
            }
        }
        .page,.current,.extend {
            margin-inline: 0.36rem;
        }
        // 現在のページ番号
        .current {
            color: $color-gray1;
        }
        .first {
            margin-right: 1.5rem;
            letter-spacing: -0.2rem;
        }
        .last {
            margin-left: 1.5rem;
            letter-spacing: -0.2rem;
        }
        .previouspostslink {
            margin-right: 1rem;
        }
        .nextpostslink {
            margin-left: 1rem;
        }
    }
}