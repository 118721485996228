@charset "UTF-8";
@use "../global" as *;


.wrapper {
  @include wrapper;
}

.wrapper2 {
  @include wrapper2;
}