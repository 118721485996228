@charset "UTF-8";
@use "../global" as *;

.l-main {
  margin-top: 105px;
  @include bpPcs {
    margin-top: 88px;
  }
  @include bpTb {
    margin-top: 5.2rem;
  }
}

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-gray3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 500;
  .l-header__container {
    display: flex;
    justify-content: space-between;
    @include bpPcs {
      align-items: center;
    }
  }
  .l-header__logo {
    display: block;
    padding: 12px;
    @include bpPcs {
      width: min(100%, 160px);
    }
    @include bpTb {
      width: min(100%, 10rem);
      padding: 0.6rem;
    }
  }
  .l-header__inner {
    @include bpPcs {
      display: none;
      width: 100%;
      height: 100%;
      position: fixed;
      padding: 0;
      top: 0;
      left: 0;
      z-index: 100;
      background-color: $color-gray3;
    }
  }
  .l-header__area {
    height: 100%;
    @include bpPcs {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
  .l-header-nav {
    height: 100%;
    .list {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      @include bpPcs {
        display: block;
        height: auto;
        width: min(85%, 300px);
        padding-top: 100px;
        padding-bottom: 100px;
        margin-inline: auto;
      }
      @include bpTb {
        width: min(85%, 25rem);
        padding-top: 10rem;
        padding-bottom: 10rem;
      }
      .list__item {
        display: flex;
        align-items: center;
        padding-inline: 30px;
        height: 100%;
        @include bpPc {
          padding-inline: 15px;
        }
        @include bpPcs {
          display: block;
          height: auto;
          padding-inline: 0;
          margin-top: 35px;
        }
        @include bpTb {
          margin-top: 1.7rem;
        }
      }
      .list__link {
        display: inline-block;
        font-size: 16px;
        @include font(16, 400, 24, 50);
        transition: color .2s ease;
        @include bpTb {
          font-size: 1.6rem;
        }
        @include hover {
          color: $color-blue3;
        }
      }
      // 子リンク
      .list-child {
        display: none;
        width: min(100%, 242px);
        position: absolute;
        top: 111px;
        left: -17px;
        background-color: $whiteColor;
        opacity: 0.88;
        @include bpPc {
          left: -19px;
          width: min(100%, 200px);
        }
        @include bpPcs {
          display: block;
          position: relative;
          width: auto;
          top: auto;
          left: auto;
          background-color: transparent;
        }
        .list-child__item {
        }
        .list-child__link {
          display: block;
          font-size: 15px;
          @include font(15, 400, 24, 50);
          text-align: right;
          padding: 0.84em 1.9em;
          transition: color .2s ease, background-color .2s ease;
          @include bpPcs {
            display: inline-block;
            text-align: left;
            padding: 0;
            margin-left: 1em;
            margin-top: 1em;
          }
          @include bpTb {
            font-size: 1.5rem;
          }
          &::before {
              @include bpPcs {
              content: '・';
              display: inline-block;
            }
          }
          @include hover {
            color: $whiteColor;
            background: $color-gr1;
          }
        }
      }
      // お問い合わせ
      .list__item--contact {
        color: $whiteColor;
        background: $color-gr1;
        margin-left: 46px;
        padding-inline: 0;
        @include bpPc {
          margin-left: 25px;
        }
        @include bpPcs {
          justify-content: center;
          width: min(100%, 300px);
          margin-left: auto;
          margin-right: auto;
        }
        @include bpTb {
          width: min(100%, 25rem);
        }
        .list__link {
          display: flex;
          align-items: center;
          font-size: 17px;
          @include font(17, 500, 24, 200);
          height: 100%;
          padding-inline: 31px;
          transition: color .2s ease, background-color .2s ease;
          @include bpPc {
            padding-inline: 18px;
          }
          @include bpPcs {
            justify-content: center;
            width: 100%;
            padding: 1em 0.5em;
            text-align: center;
          }
          @include bpTb {
            font-size: 1.7rem;
          }
          @include hover {
            color: $color-blue3;
            background-color: #94C7F0;
          }
        }
      }
    }
  }
  // ハンバーガーメニュー
  .l-header-menu {
    display: none;
    @include bpPcs {
      display: block;
      width: 70px;
      height: 70px;
      margin-right: 12px;
      position: relative;
      cursor: pointer;
      z-index: 9999;
    }
    @include bpTb {
      width: 5rem;
      height: 5rem;
      margin-right: 0.6rem;
    }
      span {
        display: inline-block;
        position: absolute;
        width: 60%;
        height: 4px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $textColor;
        transition: all ease 0.5s;
        border-radius: 10px;
        @include bpTb {
          height: 0.3rem;
          border-radius: 0.1rem;
        }
      }
      span:nth-of-type(1) {
        top: 20px;
        @include bpTb {
          top: 1.5rem;
        }
      }
      span:nth-of-type(2) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
      span:nth-of-type(3) {
        bottom: 20px;
        @include bpTb {
          bottom: 1.5rem;
        }
      }
  }
  .l-header-menu.active {
    span:nth-of-type(1) {
      top: 47%;
      transform: translateX(-50%) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      bottom: 46%;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}