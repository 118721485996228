@charset "UTF-8";
@use "../global" as *;

.l-footer {
    background-image: url(../img/footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    margin-top: -50px;
    position: relative;
    @include bpTb {
        margin-top: -4.5rem;
    }
    .l-footer__copy {
        font-size: $text-12;
        @include font(12, 400, 24, 50);
        position: fixed;
        top: 50%;
        left: -18px;
        transform: translateY(-50%) rotate(90deg);
        z-index: 50;
        @include bpTb {
            font-size: $text-12-sp;
            position: absolute;
            top: auto;
            bottom: 0.5rem;
            left: 50%;
            transform: translateX(-50%);
            color: $whiteColor;
        }
    }
    .l-footer__pagetop {
        padding: 10px;
        position: fixed;
        right: 7px;
        bottom: 40px;
        z-index: 50;
        cursor: pointer;
        @include bpTb {
            width: 3.6rem;
            padding: 0.5rem;
            right: 0.7rem;
            bottom: 4rem;
        }
        @include hover {
            transition: opacity .2s ease;
            opacity: .8;
        }
    }
    .l-footer__container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: 52px;
        padding-bottom: 57px;
        @include bpTb {
            display: block;
            padding-top: 7rem;
            padding-bottom: 5.5rem;
        }
    }
    .l-footer-company {
        width: min(100%, 250px);
        margin-right: 50px;
        @include bpTb {
            width: 100%;
            margin-right: 0;
        }
        .l-footer-company__logo {
        
        }
        .l-footer-company__head {
            font-size: $text-22;
            @include font(22, 400, 30, 100);
            margin-top: 52px;
            color: $whiteColor;
            @include bpTb {
                font-size: $text-22-sp;
                margin-top: 3rem;
            }
        }
        .detail {
            margin-top: 30px;
            @include bpTb {
                margin-top: 3rem;
            }
            .detail__text {
                font-size: $text-13;
                @include font(13, 400, 20, 100);
                margin-top: 19px;
                color: $whiteColor;
                @include bpTb {
                    font-size: $text-13-sp;
                    margin-top: 1.5rem;
                }
                &:first-child {
                    margin-top: 0;
                }
                .tellink {
                
                }
            }
        }
    }
    .l-footer-links {
        width: 61.8%;
        @include bpTb {
            width: 100%;
        }
        .page {
            display: flex;
            justify-content: flex-end;
            @include bpPcs {
                flex-wrap: wrap;
            }
            @include bpTb {
                display: block;
                margin-top: 4.5rem;
            }
            .page__item {
                margin-left: 29px;
                @include bpTb {
                    margin-left: 0;
                    margin-top: 1.2rem;
                }
                &:first-child {
                    margin-top: 0;
                    @include bpPcs {
                        margin-bottom: 10px;
                    }
                    @include bpTb {
                        margin-bottom: 0;
                    }
                }
            }
            .page__link {
                display: inline-block;
                font-size: $text-14;
                @include font(14, 400, 20, 50);
                color: $whiteColor;
                @include bpTb {
                    font-size: $text-14-sp;
                }
            }
        }
        .links {
            display: flex;
            justify-content: flex-end;
            margin-top: 81px;
            column-gap: 12px;
            @include bpTb {
                margin-top: 5rem;
            }
            .links__item {
            
                img {
                    @include bpTb {
                        width: 100%;
                    }
                }
            }
        }
    }
}